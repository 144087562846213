<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-toolbar color="primary darken-1" dark>
              <v-card-title>
                <span class="">{{
                  (editedIndex === -1 ? $t("add") : $t("edit")) +
                  " " +
                  $t("categories.category")
                }}</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    persistent-hint
                    v-model="editedItem.categoryName"
                    :rules="[rules.required]"
                    :label="$t('categories.codeName')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    persistent-hint
                    v-model="editedItem.categoryCaption"
                    :rules="[rules.required]"
                    :label="$t('categories.displayName')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-select
                    :items="products"
                    item-text="productName"
                    item-value="productGuid"
                    :rules="[rules.required]"
                    v-model="editedItem.productGuid"
                    :label="$t('categories.productName')"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="green darken-1"
                class="white--text"
                @click="save"
                :disabled="!valid"
                :min-width="100"
              >
                <v-icon>mdi-content-save-outline</v-icon>
                {{ $t("save") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <confirm-dialog
        :openDialog="dialogDelete"
        :onClicked="deleteItemConfirm"
        :onClose="closeDelete"
      ></confirm-dialog>
      <v-data-table
        :headers="headers"
        :items="categories"
        :search="search"
        :items-per-page="5"
        :loading="loading"
        :loading-text="$t('loading')"
        class=""
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" @click="dialog = !dialog">
              {{ $t("add") }}
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="text-end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mx-1"
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("edit") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red darken-2"
                  icon
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("delete") }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
export default {
  components: { ConfirmDialog },
  data() {
    return {
      loading: true,
      valid: true,
      valid1: true,
      dialog: false,
      dialogDelete: false,
      dialogChangePassword: false,
      editedIndex: -1,
      search: "",
      headers: [
        { text: this.$t("categories.codeName"), value: "categoryName" },
        { text: this.$t("categories.displayName"), value: "categoryCaption" },
        {
          text: this.$t("categories.productName"),
          value: "product.productName",
        },
        { text: "", value: "actions" },
      ],
      categories: [],
      products: [],
      editedItem: {},
      defaultItem: {
        categoryGuid: null,
        categoryName: null,
        categoryCaption: null,
        productGuid: null,
      },
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },
  created() {
    this.loading = true;
    this.refreshTable();
    this.getProducts();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.categories.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.categories.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      var deleteItem = this.categories[this.editedIndex];
      axios
        .delete("Categories/Delete?id=" + deleteItem.categoryGuid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },
    refreshTable() {
      axios
        .get("Categories/Get")
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getProducts() {
      axios
        .get("Products/Get")
        .then((response) => {
          this.products = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.categories[this.editedIndex];
          updatedItem.categoryName = this.editedItem.categoryName;
          updatedItem.categoryCaption = this.editedItem.categoryCaption;
          updatedItem.productGuid = this.editedItem.productGuid;

          axios
            .post("Categories/Save", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            })
            .finally(() => {
              this.loading = false;
              this.refreshTable();
            });
          Object.assign(this.categories[this.editedIndex], this.editedItem);
        } else {
          this.editedItem.categoryGuid = "00000000-0000-0000-0000-000000000000";
          axios
            .post("Categories/Save", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            })
            .finally(() => {
              this.loading = false;
              this.refreshTable();
            });
        }
      }
    },
  },
};
</script>
